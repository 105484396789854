import V from 'voUtils/V.js';
import QuoteTools from 'voUtils/QuoteTools.js';

import {
	doReq
}
from 'voUtils/RestEx.js';
import {
	fireQuote, fireUnitAction, fireCheckout
}
from 'voUtils/TagEx.js';
import {
	find, filter, isEmpty, oneIs
}
from 'voUtils/tools.js';
import DateEx from 'voUtils/DateEx.js';

export default {

	data: {
		bookingData: {
			adults: 2,
			children: 0,
			childrenAges: undefined,
			babys: 0,
			petsCount: 0,
			from: undefined,
			till: undefined
		},
		action: undefined,
		step: undefined, //'booking',
		priceIsUpdated: false,
		showPriceDetails: false,
		quote: {},
		error: undefined,
		preview: undefined,
		summary: undefined,
		selectedImageIndex: undefined,
		showFullDesc: false,
		serviceLineAmount: undefined
	},
	created: function () {
		this.calListeners = [];
		this.priceListeners = [];
		this.QuoteTools = QuoteTools;

		var params = new URLSearchParams(new URL(window.location).search);
		for (let [k, v] of params) {
			if (oneIs(k, 'ref_adults', 'ref_children', 'ref_babys', 'ref_from', 'ref_till')) {
				this.bookingData[k.substring(4)] = v;
			}
		}

		var hash = window.location.hash;
		if (hash) {
			hash = hash.substring(1);

			let url = new URL(hash, 'https://www.v-office.com');
			let params = new URLSearchParams(url.search);

			for (let p of params) {
				if ((p[0] == 'children' || p[0] == 'babys') && !this.areChildrenWelcome()) {
					//ignore not welcome
				} else if (p[0] == 'petsCount' && !this.arePetsWelcome()) {
					//ignore not welcome
				} else {
					this.bookingData[p[0]] = p[1];
				}
			}
			if (this.bookingData['from'] && this.bookingData['till']) {

				$('html, body').animate({
					scrollTop: $("#booking").offset().top
				}, 1000);
			}
		}


		this.updatePrices();
		this.saveLastViewedUnit();


		$(window).bind('scroll', function () {
			var currentTop = $(window).scrollTop();
			var elems = $('.scrollspy');
			elems.each(function (index) {
				var elemTop = $(this).offset().top;
				var elemBottom = elemTop + $(this).height();
				if (currentTop >= (elemTop - 250)) {
					var id = $(this).attr('id');
					var navElem = $("#" + id + 'nav');
					navElem.addClass('active').siblings().removeClass('active');
				}
			});
		});


	},
	mounted: function () {
		//Offers 
		var totalOffers = $(".unit-offer").length;
		if (totalOffers > 0) {
			//$("#Angebote").show();
			$("#Angebotenav").show();
		} else {
			$("#Angebotenav").hide();
			$("#Angebote").hide();
		}
	},

	computed: {

		showBookingButtons: function () {
			return this.quote && this.quote.mandatory && !this.priceIsUpdated && isEmpty(this.quote.errors) && !this.action;
		},
		canDoNext: function () {
			return this.quote && this.quote.mandatory && !this.priceIsUpdated && isEmpty(this.quote.errors) && this.action;
		},
		periodLabel: function () {
			let label = '';
			if (this.bookingData.from) {
				label = this.voFormatDate(this.bookingData.from, 'withDay');
			}
			if (this.bookingData.till) {
				label += ' - ' + this.voFormatDate(this.bookingData.till, 'withDay');
			}

			return label;
		},
		guestLabel: function () {
			var sd = this.bookingData;
			var amount = ((sd.adults || 0) - 0) + ((sd.children || 0) - 0) + ((sd.babys || 0) - 0);
			var label = amount + ' ' + this.voMsgPl('guest.pl', amount);
			if (sd.petsCount) {
				label += ', ' + sd.petsCount + ' ' + this.voMsgPl('pet.pl', sd.petsCount);

			}
			return label;
		}


	},
	updated: function () {
		$('.offer-block').each(function (index, obj) {
			if ($(obj).find('div').length == 0) {
				$(obj).remove();
			}
		});
		/*$('.fixnavigationbar').affix({
			offset: {
				top: 400
			}
		});*/
		$(".owl-carousel-unit").owlCarousel({
			items: 1,
			loop: false,
			rewind: true,
			center: true,
			autoplay: false,
			smartSpeed: 3000,
			autoplayTimeout: 10000,
			dots: true,
			dotsSpeed: 500,
			nav: true
		});

		$(".owl-carousel-feedback").owlCarousel({
			margin: 5,
			loop: true,
			autoplay: false,
			smartSpeed: 1000,
			dots: true,
			dotsSpeed: 1000,
			nav: true,
			responsive: {
				0: {
					items: 1
				},
				600: {
					items: 2
				},
				1000: {
					items: 3
				}
			}
		});

		$(".139891").each(function () {
			$(this).href = './aparthotel-anna-duene-wangerooge';
			$(this).prop('href', './aparthotel-anna-duene-wangerooge');
		});
		$(".139902").each(function () {
			$(this).prop('href', './aparthotel-waterkant-suites');
			$(this).href = './aparthotel-waterkant-suites';
		});
		$(".139914").each(function () {
			$(this).prop('href', './aparthotel-ostseeallee-boltenhagen');
			$(this).href = './aparthotel-ostseeallee-boltenhagen';
		});
		$(".139916").each(function () {
			$(this).prop('href', './ostseeheilbad-travemuende');
			$(this).href = './aparthotel-ostseeheilbad-travemuende';
		});
		$(".139917").each(function () {
			$(this).prop('href', './ostseebad-grossenbrode');
			$(this).href = './aparthotel-ostseebad-grossenbrode';
		});
		$(".139921").each(function () {
			$(this).prop('href', './hansestadt-wismar');
			$(this).href = './hansestadt-wismar';
		});
		$(".160536").each(function () {
			$(this).prop('href', './krusespeicher');
			$(this).href = './krusespeicher';
		});

	},

	/*watch: {
		'bookingData.till': function () {
			if (this.bookingData.till) {
				console.log('till date is changed....' + this.bookingData.till);
				this.updatePrices();
			}
		}
	},*/

	methods: {
		getPrices: function (done) {
			if (this.prices) {
				done(this.prices);
			} else {
				this.priceListeners.push(done);

				if (this.priceListeners.length === 1) {

					var req = {
						id: this.getUnitId()
					};

					var params = {
						'noCache': true
					};
					console.log('Fetching priecs 2...');
					doReq('getPricelist', req, params)
						.then((res) => {
							this.prices = res.prices;

							for (let l of this.priceListeners) {
								l(res.prices);
							}
							this.priceListeners.length = 0;
						});


				}
			}
		},
		showKidsServiceMessage: function (lines) {
			console.log('m lines::' + this.quote.mandatory);
			console.log('o lines::' + this.quote.onsite);
			var showMessage = false;
			if (this.quote.mandatory) {
				if (this.bookingData.children > 0 || this.bookingData.babys > 0) {
					showMessage = true;
					for (let i = 0; i < this.quote.mandatory.length; i++) {
						var selectedServiceId = this.quote.mandatory[i].service.id;
						if (selectedServiceId == 29027 || selectedServiceId == 29030 || selectedServiceId == 29034) {
							showMessage = false;
						}
					}
				}
			}
			/*for (let i = 0; i < this.quote.onsite.length; i++) {
				console.log('o sl.id' + this.quote.onsite[i].service.id);
			}*/

			return showMessage;
		},
		canShowLineItem: function (lineId) {
			if (this.bookingData.children <= 0 && this.bookingData.babys <= 0) {
				if (lineId == 29027 || lineId == 29030 || lineId == 29034) {
					return false;
				}
			}
			return this.showPriceDetails;
		},
		toggleDescClass: function () {
			this.showFullDesc = !this.showFullDesc;
		},
		getRegionPhoneNumber: function (regionName) {
			if (regionName == 'Kellenhusen') {
				return '+494364-1041';
			} else if (regionName == 'Dahme') {
				return '+494364-8282';
			} else if (regionName == 'Scharbeutz') {
				return '+494503 - 8982525';
			} else {
				return '+494562 - 22 33 00';
			}
		},
		addLine: function (quote, sl) {
			console.log('addLine SL::', sl);
			QuoteTools.addLine(quote, sl);
			console.log('addLine2 SL::', sl);
			this.$forceUpdate();
		},
		removeLine: function (quote, sl) {
			console.log('removeLine SL::', sl);
			QuoteTools.removeLine(quote, sl);
			this.$forceUpdate();
		},

		getSlOptions: function (id) {
			var count = 10;
			var str = '<option value="undefined">0</option>';
			var amount = 0;
			if (this.quote.serviceLimits && this.quote.serviceLimits[id] && typeof this.quote.serviceLimits[id].maxPerBooking !== 'undefined') {
				count = this.quote.serviceLimits[id].maxPerBooking;
			}
			//console.log('Mandadory::', this.quote.mandatory);
			for (let i = 0; i < this.quote.mandatory.length; i++) {
				if (this.quote.mandatory[i].service.id === id) {
					amount = this.quote.mandatory[i].amount;
				}
			}

			for (let i = 1; i <= count; i++) {
				if (i === amount) {
					str += '<option value="' + i + '" selected>' + i + '</option>';
				} else {
					str += '<option value="' + i + '">' + i + '</option>';
				}
			}
			return str;
		},
		handleService: function (quote, sl) {
			var id = 'sl_' + sl.service.id;
			var amount = document.getElementById(id).value;

			if (amount === 'undefined') {
				sl.amount = null;
				this.removeLine(quote, sl);
			} else if (amount > sl.amount) {
				sl.amount = parseInt(amount) - 1;
				this.addLine(quote, sl);
			} else if (amount < sl.amount) {
				sl.amount = parseInt(amount) + 1;
				console.log('slkleiner::', sl);
				this.removeLine(quote, sl);
			}

			//console.log('Quote::', quote, 'SL::', sl, 'Amount::', amount);
		},

		clearPrices: function () {
			this.quote = {};
			this.summary = undefined;
			this.preview = undefined;
			this.previewQuote = undefined;
		},
		updatePrices: function () {

			var data = this.bookingData;
			this.error = undefined;
			if (data.from && data.till) {

				this.priceIsUpdated = true;
				this.quote = {};

				var req = {
					fromdate: DateEx.interfaceFormat(data.from),
					tilldate: DateEx.interfaceFormat(data.till),
					adults: data.adults,
					children: data.children,
					babys: data.babys,
					petsCount: data.petsCount,
					includeServiceLimits: true,
					unit: this.getUnitId()
				};

				if (VOFFICE.quoteOptions) {
					Object.assign(req, VOFFICE.quoteOptions);
				}

				if (this.voucher) {
					req.voucherCode = this.voucher.code;
				}

				if (VOFFICE.abtest) {
					req.abtest = VOFFICE.abtest;
				}

				if (data.childrenAges) {
					req.childrenAges = data.childrenAges;
				}

				doReq('quotePrices', req)
					.then((res) => {
						this.quote = res.quote;
						this.priceIsUpdated = false;
						this.quote.optional.forEach(sl => {
							sl.optional = true;
							sl.amount = null;
							this.QuoteTools.updateTotal(sl);
						});
						this.quote.onsiteOptional.forEach(sl => {
							sl.onsiteOptional = true;
							sl.amount = null;
							this.QuoteTools.updateTotal(sl);
						});

						if (res.quote.mandatory) {
							this.summary = find(res.quote.mandatory, function (sl) {
								return sl.service.type === 'SUMMARY';
							});

							this.preview = filter(res.quote.mandatory, function (sl) {
								return !VOFFICE.hideTypeOnPricePreview || VOFFICE.hideTypeOnPricePreview.indexOf(sl.service.type) == -1;
							});

							if (!this.preview || this.preview.length < 2) {
								this.preview = undefined;
								this.previewQuote = undefined;
							} else {
								this.previewQuote = {
									mandatory: this.preview,
									hasDiscounts: this.quote.hasDiscounts
								};
							}

						}

						fireQuote(this.getCart());
						this.goAction('booking');

					})
					.catch(e => {
						this.priceIsUpdated = false;
						this.error = e;
					});


			}

		},
		getCart: function () {
			return {
				action: this.action,
				quote: this.quote,
				booking: Object.assign({}, this.bookingData),
				unitId: this.getUnitId()
			};
		},

		goAction: function (action) {
			this.action = action;
			this.showPriceDetails = true;
			fireUnitAction(this.getCart());
		},
		saveLastViewedUnit: function () {
			var unitId = this.getUnitId();
			var data = this.bookingData;
			var units = [];
			var storedUnits = {};
			var suffix = VOFFICE.token.substr(VOFFICE.token.length - 12);
			var savedUnits = JSON.parse(localStorage.getItem('lastVisitedUnits_' + suffix));
			var count = 0;
			if (savedUnits) {
				count = Object.keys(savedUnits).length;
			}
			units[0] = {
				'_id': unitId,
				'till': data.till,
				'from': data.from,
				'adults': data.adults,
				'children': data.children,
				'babys': data.babys
			};

			if (count > 0) {

				for (var index = 0; index < count; index++) {
					if (parseInt(savedUnits[index]._id) === parseInt(unitId)) {
						console.log('Objekt gibt es schon');
					} else {
						units[index + 1] = savedUnits[index];
					}
				}

			}
			var i = 0;
			const maxLastView = (VOFFICE.settings && VOFFICE.settings.maxLastView) || 5;
			for (var _unit in units) {
				if (i < maxLastView) {
					storedUnits[i] = units[_unit];
				}
				i++;
			}

			var strJson = JSON.stringify(storedUnits);

			localStorage.setItem('lastVisitedUnits_' + suffix, JSON.stringify(storedUnits));

		},

		putInCart: function () {
			this.step = 'booking';
			fireCheckout(this.getCart());
			window.scrollTo(0, 0);

		},
		cancelBooking: function () {
			this.step = undefined;
		},
		cancel: function () {
			this.action = undefined;
			this.showPriceDetails = false;

		},


		getUnitId: function () {
			if (!this.unitId) {
				let el = document.querySelector("[data-unit-id]");
				if (el) {
					this.unitId = parseInt(el.getAttribute('data-unit-id'), 10);
				}
			}
			return this.unitId;
		},
		areChildrenWelcome: function () {
			if (this.childrenWelcome == undefined) {
				let el = document.querySelector("[data-children-welcome]");
				if (el) {
					this.childrenWelcome = el.getAttribute('data-children-welcome') == '1';
				} else {
					this.childrenWelcome = false;
				}
			}
			return this.childrenWelcome;
		},
		arePetsWelcome: function () {
			if (this.petsWelcome == undefined) {
				let el = document.querySelector("[data-pets-welcome]");
				if (el) {
					this.petsWelcome = el.getAttribute('data-pets-welcome') == '1';
				} else {
					this.petsWelcome = false;
				}
			}
			return this.petsWelcome;
		},
		getCal: function (done) {
			if (this.cal) {
				done(this.cal);
			} else {
				this.calListeners.push(done);

				if (this.calListeners.length === 1) {

					var req = {
						unit: this.getUnitId()
					};

					doReq('getCal', req)
						.then((res) => {
							this.cal = res.cal;

							for (let l of this.calListeners) {
								l(res.cal);
							}
							this.calListeners.length = 0;

						});
				}
			}
		},

		getDays: function (date) {
			const today = DateEx.interfaceFormat(DateEx.getToday());
			let diff = DateEx.getDays(date, today);
			return diff;

		},

		checkOffer: function (o, unitoftheday) {
			var lang = VOFFICE.lang;
			this.lang = lang;

			//	console.log('offer:::', o);
			//	console.log('voucherAngebot::', o.voucherCode);
			if (o.voucherCode) {
				//console.log('voucherAngebot::', o.name[lang]);
				if (!this.checkVoucherCode(o.voucherCode)) {
					//console.log('voucherAngebot::', o.name[lang]);
					return false;
				}
			}
			if (o.type === 'EARLYBIRD') {
				if (!this.check4EarlyBird(o)) {
					return false;
				}
			} else if (o.type === 'LASTMINUTE') {
				if (!this.check4LastMinute(o)) {
					return false;
				}
			} else if (o.type === 'UNITOFDAY') {
				if (!unitoftheday) {
					return false;
				}
			}
			if (!this.check4OfferEnd(o)) {
				return false;
			}

			return true;
		},
		checkVoucherCode: function (code) {
			if (code) {
				if (this.voucher && code === this.voucher.code) {
					return true;
				} else {
					return false;
				}

			}
		},
		check4LastMinute: function (o) {
			var timeSpan = parseInt(o.minStay);
			var tilldate = DateEx.interfaceFormat(o.tilldate);
			var fromdate = DateEx.interfaceFormat(o.fromdate);
			var minStay = parseInt(o.minStay);
			var daysToFromdate;
			if (fromdate) {
				daysToFromdate = this.getDays(fromdate);
				if (timeSpan) {
					if (daysToFromdate > timeSpan) {
						//console.log('Lastminute offer not bookable because offer start date is not reached');
						return false;
					}
				}
			}
			return true;

		},
		check4EarlyBird: function (o) {
			var timeSpan = parseInt(o.minStay);
			var tilldate = DateEx.interfaceFormat(o.tilldate);
			//var fromdate = '2023-12-01';
			var fromdate = DateEx.interfaceFormat(o.fromdate);
			var minStay = parseInt(o.minStay);

			if (tilldate) {
				var daysToTilldate = this.getDays(tilldate);
				//console.log('Days until the start of the offer::', daysToTilldate);

				if (timeSpan) {
					if (daysToTilldate <= timeSpan + 1) {
						//console.log('Earlybird offer is not bookable because the offer has already expired');
						return false;
					} else if (minStay) {
						if (daysToTilldate < (timeSpan + minStay)) {
							//console.log('Earlybird offer is not bookable because the offer has already expired, due to minimum rental period');
							return false;
						}
					}
				}
			}

			if (fromdate) {
				var daysToFromdate = this.getDays(fromdate);
				//console.log('Days until the end of the offer::', daysToFromdate);

				if (timeSpan) {
					if (daysToFromdate > timeSpan) {
						// console.log('Offer not bookable because offer start date is not reached');
						return false;
					}
				}
			}
			return true;
		},
		check4OfferEnd: function (o) {
			var minStay = parseInt(o.minStay);
			var tilldate = DateEx.interfaceFormat(o.tilldate);
			var daysToTilldate = this.getDays(tilldate);

			if (minStay && tilldate) {
				//console.log('daysToTilldate', daysToTilldate);
				if (daysToTilldate < minStay) {
					//console.log('Offer not bookable because the minimum rental period exceeds the offer end date');
					return false;
				}
			}
			return true;
		},
	},
	watch: {
		'bookingData.from': function () {
			if (!this.bookingData.till) {
				this.clearPrices();
			}
		}

	}



};