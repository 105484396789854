export default {
	data: {
		selectedImageIndex: undefined,
		showAnmelden: true
	},
	beforeMount: function () {
		//console.warn("ha", window.location.search);
		var url = new URL(window.location.href);
		var params = {};
		for (let key of url.searchParams.keys()) {
			//console.log("p", key, url.searchParams.get(key));
			params[key] = url.searchParams.get(key);

		}
		this.searchParams = params;
	},
	mounted: function () {
		$(".regionSection").each(function () {
			if ($(this).find('.panel').length == 0) {
				$(this).hide(); // Hide sections with no panels
			}
		});

		$(".region-wrap").hide(); // Initially hide all region-wrap elements


		// Prepend Font Awesome icons to each h2
		$(".regionSection h2").each(function () {
			$(this).prepend('<i class="far fa-plus-circle toggle-icon"></i> ');
		});

		$(".regionSection h2").click(function () {
			$(this).siblings(".region-wrap").slideToggle(); // Toggle visibility

			// Toggle between plus and minus icons
			let icon = $(this).find(".toggle-icon");
			if (icon.hasClass("fa-plus-circle")) {
				icon.removeClass("fa-plus-circle").addClass("fa-minus-circle");
			} else {
				icon.removeClass("fa-minus-circle").addClass("fa-plus-circle");
			}
		});


	},
	updated: function () {
		$('[data-toggle="tooltip"]').tooltip();
		$('[data-toggle="popover"]').popover();


	},
	computed: {
		mailto: function () {
			return `mailto:${this.emailAddress}`;
		},
		emailAddress: function () {
			return (VOFFICE.contactEmail || ['info', 'test.de']).join('@');
		}
	}

};